import styled from "styled-components";

const Sproject = styled.div`
    display: flex;
    gap: 16px;

    img {
        width: 50%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    }

    div {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 4px;

            h3 {
                font-size: 1.5rem;
                color: #333;
                text-align: start;
                margin: 0;
            }

            a {
                font-size: 1rem;
                font-weight: 400;
                color: #35a7e4;
            }
        }

        p {
            font-size: 1rem;
            color: #333;
            margin: 16px 0;
        }
    }

    // mobile
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;

        img {
            width: 100%;
        }

        div {
            div {
                h3 {
                    font-size: 1.2rem;
                }

                a {
                    display: none;
                }
            }

            p {
                font-size: 0.8rem;
                margin: 6px 0;
            }
        }
    }
`;

const Stag = styled.span`
    font-size: 12px;
    text-align: start;
    padding: 4px 12px;
    border-radius: 8px;
    background-color: #35a7e4;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;

    // mobile
    @media (max-width: 768px) {
        display: none;
    }
`;

const Svisit = styled.a`
    display: none;

    // mobile
    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        padding: 8px 16px;
        font-size: 1rem;
        margin: 0 auto 16px auto;
        font-weight: 600;
        color: #fff;
        background-color: #35a7e4;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
        text-decoration: none;

        &:active {
            transform: scale(0.95);
        }
    }
`;

const Project = ({ img, tag, nome, link, descricao }) => {
    return (
        <Sproject>
            <img src={img} alt={nome} />
            <div>
                <div>
                    <Stag>{tag}</Stag>
                    <h3>{nome}</h3>
                    <a href={link} target="_blank" rel="noreferrer">{link}</a>
                </div>
                <p>{descricao}</p>
            </div>
            <Svisit href={link} target="_blank" rel="noreferrer">Visitar Site</Svisit>
        </Sproject>
    );
}

export default Project;