import styled from "styled-components";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";

const Sfooter = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    background-color: #333;
    color: #fff;
    font-size: 1rem;

    p {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    a {
      transition: all 0.3s;
      display: flex;
      margin-top: 5px;
      color: #fff;

      &:hover {
        color: #999;
      }
    }

    // mobile
    @media (max-width: 768px) {
        height: 50px;
        font-size: 0.8rem;
    }
`;


const Footer = () => {
    return (
        <Sfooter id="Footer">
        <p>
            Desenvolvido por Álvaro Cardoso
            <a href="https://www.linkedin.com/in/cardosoalvaro/" target="_blank" rel="noreferrer">
                <FaLinkedinIn />
            </a>
            <a href="https://github.com/AlvaroRCardoso" target="_blank" rel="noreferrer">
                <FaGithub />
            </a>
        </p>
        </Sfooter>
    );
};

export default Footer;