import styled from "styled-components";
import Project from "../Project";

const Sprojects = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap row;
    width: 1200px;
    background-color: #f3f3f3;
    gap: 32px;

    h3 {
        width: 100%;
        text-align: center;
        font-size: 2rem;
        color: #333;
        margin: 16px 0;
    }

    // mobile
    @media (max-width: 768px) {
        width: 300px;
        gap: 16px;

        h3 {
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 0;
        }
    }
`;

const projetos = [
    {
        id: 1,
        img: "/site-sollar.png",
        tag: "Site",
        nome: "Sollar Sul",
        link: "https://sollarsul.com.br/",
        descricao: "Esse é o primeiro site que desenvolvi foi para a empresa onde ainda trabalho hoje, criando aplicativos e sistemas mais complexos, embora esses trabalhos não sejam públicos. Esse projeto foi extremamente importante para minha carreira, pois representou minha primeira oportunidade como programador. Ele me permitiu demonstrar minhas habilidades e abrir caminho para desafios maiores e mais avançados no campo da tecnologia."
    },
    {
        id: 2,
        img: "/site-hw.png",
        tag: "Site",
        nome: "Hard Work",
        link: "https://hardwork.imb.br/",
        descricao: "Esse é um dos últimos sites que desenvolvi como freelancer, criado com o objetivo de apresentar um novo complexo de lojas. O site foi projetado com foco na experiência do usuário, utilizando as melhores práticas de design e desenvolvimento. Incorporando tecnologias como HTML5, CSS3, e JavaScript, o site oferece uma navegação fluida e intuitiva, além de ser totalmente otimizado para dispositivos móveis. Esse projeto é um exemplo do meu compromisso em entregar soluções digitais de alta qualidade, alinhadas às necessidades e objetivos do cliente."
    }
];

const Projects = () => {
    return (
        <Sprojects>
            <h3>Projetos</h3>
            {projetos.map((projeto) => (
                <Project key={projeto.id} img={projeto.img} tag={projeto.tag} nome={projeto.nome} link={projeto.link} descricao={projeto.descricao} />
            ))}
        </Sprojects>
    );
}

export default Projects;
