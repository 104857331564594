import styled from "styled-components";

const Spresentation = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    width: 100%;
    text-align: start;
    font-size: 5rem;
    color: #fff;
    margin-bottom: 0;
  }

  h3 {
    width: 100%;
    text-align: start;
    font-size: 3rem;
    line-height: 0.7;
    color: #fff;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  // mobile
  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
      text-align: center;
    }

    h3 {
      font-size: 1.5rem;
      line-height: 0.7;
      text-align: center;
    }
  }
`;

const Presentation = () => {
  return (
    <Spresentation>
      <h1>Álvaro Cardoso</h1>
      <h3>Desenvolvedor Full Stack</h3>
    </Spresentation>
  );
}

export default Presentation;