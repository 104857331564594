import styled from "styled-components";
import { useState } from "react";
import emailjs from "@emailjs/browser"

const Scontact = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1200px;

    h3 {
        font-size: 2rem;
        color: #333;
    }

    form {
        display: flex;
        flex-flow: wrap row;
        align-items: center;
        gap: 16px;
        width: 100%;

        input, textarea {
            flex: 1;
            padding: 8px;
            box-sizing: border-box;
            border: 1px solid #333;
            border-radius: 4px;
            background-color: transparent;
        }
        input, textarea:focus {
            outline: none;
        }

        textarea {
            flex: auto;
            width: 100%;
            height: 150px;
            resize: none;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            margin-left: auto;
            padding: 8px 16px;
            font-size: 1rem;
            font-weight: 600;
            color: #fff;
            background-color: #35a7e4;
            border: solid 1px #35a7e4;
            border-radius: 8px;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s;
        }
        button:hover {
            border: solid 1px #35a7e4;
            background-color: #fff;
            color: #35a7e4;
        }
    }

    // mobile
    @media (max-width: 768px) {
        width: 300px;

        h3 {
            font-size: 1.5rem;
            text-align: center;
            margin: 0 0 16px 0;
        }

        form {
            gap: 8px;

            input, textarea {
                padding: 4px;
                font-size: 0.8rem;
            }

            textarea {
                height: 100px;
            }

            button {
                padding: 8px 16px;
                width: fit-content;
                font-size: 1rem;
                margin: 0 auto;
            }
        }
    }
`;

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!name || !email || !message) {
            alert('Preencha todos os campos!');
            return;
        }

        const templateParams = {
            from_name: name,
            email: email,
            message: message
        }

        emailjs.send('service_c6uk4c8', 'template_20tqdjq', templateParams, 'IlJRFIOWnULCRqiQ9')
        .then((response) => {
            console.log('Sucesso!', response.status, response.text);
            setName('');
            setEmail('');
            setMessage('');
        }, (error) => {
            console.log('Erro!', error);
        });
    }

    return (
        <Scontact>
            <h3>Contato</h3>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                    placeholder="Mensagem"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit">Enviar</button>
            </form>
        </Scontact>
    )
}

export default Contact;