import styled from "styled-components";

const Sabout = styled.div`
  display: flex;
  justify-content: center;
  gap: 22px;
  width: 1200px;
  background-color: #f3f3f3;

  img {
    width: 450px;
    height: auto;
    border-radius: 22px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: start;

    h3 {
      font-size: 2rem;
      color: #333;
      margin: 16px 0;
    }

    p {
      font-size: 1rem;
      color: #333;
      text-align: justify;
      margin: 0;
    }
  }

  // mobile
  @media (max-width: 768px) {
    width: 300px;
    gap: 0px;
    align-items: center;
    flex-direction: column;

    img {
      width: 150px;
      border-radius: 8px;
    }

    div {
      h3 {
        font-size: 1.5rem;
        text-align: center;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }
`;

const About = () => {
  return (
    <Sabout>
      <img src="/eu.png" alt="Álvaro Cardoso" />
      <div>
        <h3>Sobre mim</h3>
        <p>
        Sou um desenvolvedor Full Stack com quase dois anos de experiência na área de tecnologia. Comecei minha carreira focado no desenvolvimento front-end, onde pude aprimorar minhas habilidades em HTML, CSS e JavaScript, criando interfaces de usuário interativas e responsivas. Com o tempo, ampliei meu conhecimento para o back-end, aprendendo a lidar com bancos de dados, servidores e a integração de sistemas. Hoje, me sinto confortável trabalhando em todas as camadas de uma aplicação, desde o design da interface até a lógica do servidor.
        <br></br>
        <br></br>
        Estou sempre em busca de novos desafios e de me aperfeiçoar como profissional, aplicando as melhores práticas de desenvolvimento e buscando soluções criativas para problemas complexos. Acredito no poder da tecnologia para transformar ideias em realidade e estou empolgado para continuar crescendo e contribuindo em projetos inovadores.
        </p>
      </div>
    </Sabout>
  )
};

export default About;