import ParticlesContainer from "../../componets/ParticlesContainer";
import styled from "styled-components";
import Presentation from "../../componets/Presentation";
import About from "../../componets/About";
import Projects from "../../componets/Projects";
import Contact from "../../componets/Contact";
import Footer from "../../componets/Footer";

const Sheader = styled.header`
  width: 100%;
  height: 500px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  // mobile
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const Scomponent = styled.div`
  width: 100%;
  height: min-content;
  padding: 32px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  background-color: #f3f3f3;
  position: relative;

  // mobile
  @media (max-width: 768px) {
    padding: 16px 0;
    gap: 16px;
  }
`;

const Home = () => {
  return (
    <>
      <Sheader>
        <Presentation />
        <ParticlesContainer />
      </Sheader>
      <Scomponent>
        <About />
        <Projects />
        <Contact />
      </Scomponent>
      <Footer />
    </>
  );
}

export default Home;